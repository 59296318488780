import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import eyeopen from "../../../assets/image/eyeopen.svg";
import eyesclose from "../../../assets/image/eyesclose.svg";
import "../../common/common.css";

export const StyledErrorMessage = styled("div")({
  color: "red",
  fontSize: "14px",
  marginTop: "6px",
});

export const StyledInput = styled.input`
  width: 100%;
  &::placeholder {
    opacity: ${(props) =>
    props.isFocused || props.hasValue || props.showPlaceholder ? 0 : 1};
    transition: opacity 0.3s;
  }
`;

export const StyledLabel = styled.span`
  position: absolute;
  top: ${(props) => (props.isFocused || props.hasValue ? "0px" : "15px")};
  padding: ${(props) => (props.isFocused || props.hasValue ? "0 5px" : "0")};
  left: 8px;
  color: ${(props) =>
    props.isFocused || props.hasValue ? props.labelColor : "transparent"};
  font-size: ${(props) =>
    props.isFocused || props.hasValue ? "12px" : "16px"};
  transform: ${(props) =>
    props.isFocused || props.hasValue ? "translateY(-50%)" : "none"};
  cursor: text;
  transition: top 0.3s, font-size 0.3s, transform 0.3s, color 0.3s;
  pointer-events: none;
  background-color: ${(props) =>
    props.isFocused || props.hasValue ? props.backgroundColor : "transparent"};
  &:not(:placeholder-shown)::after {
    content: ${(props) => (props.required ? "'*'" : "''")};
    color: red;
    margin-left: 2px;
  }
`;

export const StyledEyeIconImg = styled("img")`
  width: 22px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 12px;
`;

export const StyledContainter = styled("div")`
  width: 100%;
  margin-bottom: 25px;
  position: relative;
`;

export const StyledImage = styled("img")`
  height: 20px;
  width: 20px;
  position: absolute;
  left: 23px;
  top: 47%;
  transform: translateY(-50%);
`;
export const StyledAsterisk = styled.span`
  color: red;
  margin-left: 2px;
  visibility: ${(props) =>
    props.hasLabel && (props.isFocused || props.hasValue)
      ? "visible"
      : "hidden"};
`;

function TextInput(props) {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleClick = (e) => {
    setIsFocused(true);
    inputRef.current.focus();
  };

  const handleBlur = (e) => {
    setIsFocused(false);
    if (props.onBlur) {
      props.onBlur(e);
    }
  };
  const {
    type = "text",
    placeholder = "",
    name = "",
    value = "",
    onChange = () => { },
    // onBlur = () => {},
    className = "",
    id = "",
    label = "",
    error = "",
    errorMessage = "",
    required = false,
    containerClassName = "input-field",
    containerStyle = {},
    autoComplete = "",
    readOnly = false,
    passWordIcon = false,
    style = {},
    icon = null,
    open = false,
    eyeicontoggle = () => { },
    eyetoggleclassname = "",
    backgroundColor = "white",
    labelColor = "black",
  } = props;

  const labelWithAsterisk = required ? `${label} ` : label;
  return (
    <StyledContainter className={containerClassName} style={containerStyle}>
      {icon && <StyledImage src={icon} alt="icon" />}
      <StyledInput
        type={type || "text"}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        // onBlur={onBlur}
        onFocus={handleClick}
        className={className}
        id={id}
        onBlur={handleBlur}
        required={required}
        autoComplete={autoComplete}
        readOnly={readOnly}
        style={icon ? { paddingLeft: "30px", ...style } : style}
        ref={inputRef}
        isFocused={document.activeElement === inputRef.current && isFocused}
        hasValue={value !== ""}
        showPlaceholder={!placeholder}
      />
      <StyledLabel
        onClick={handleClick}
        isFocused={document.activeElement === inputRef.current && isFocused}
        hasValue={value !== ""}
        backgroundColor={backgroundColor}
        labelColor={labelColor}
        hasLabel={Boolean(label)}
      >
        {labelWithAsterisk}
        {required && (
          <StyledAsterisk
            hasLabel={Boolean(label)}
            isFocused={document.activeElement === inputRef.current && isFocused}
            hasValue={value !== ""}
          >
            *
          </StyledAsterisk>
        )}
      </StyledLabel>

      {passWordIcon && (
        <span className={eyetoggleclassname} onClick={eyeicontoggle}>
          {open ? (
            <StyledEyeIconImg src={eyeopen} alt="no img" />
          ) : (
            <StyledEyeIconImg src={eyesclose} alt="no img" />
          )}
        </span>
      )}

      {error && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </StyledContainter>
  );
}

export default TextInput;

import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Header from "../app/component/Header/Header";
import DashboardPage from "../app/component/pages/Dashboard-Page/DashboardPage";
import ReportsPage from "../app/component/pages/Reports-page/ReportsPage";
import Login from "../app/component/pages/Login/Login";
import CreateAccount from "../app/component/pages/Create-Account/CreateAccount";
import ForgotPassword from "../app/component/pages/Password/ForgotPassword";
import CandidateTableList from "../app/component/pages/Candidate-Page/CandidateTableList";
import "../app/common/common.css";
import JobsTableList from "../app/component/pages/Jobs-Page/JobsTableList";
import ClientTableList from "../app/component/pages/Client-page/ClientTableList";
import ManageAccount from "../app/component/pages/ManageAccount/ManageAccount";
import CandidatePage from "../app/component/pages/Candidate-Page/CandidatePage";
import ImportCandidate from "../app/component/pages/Candidate-Page/ImportCandidate/ImportCandidate";
import ResumeUpload from "../app/component/pages/Candidate-Page/ResumeUpload/ResumeUpload";
import EmployeeInfo from "../app/component/pages/EmployeeInfo/EmployeeInfo";
import CreateJob from "../app/component/pages/Jobs-Page/Createjob";
import CreateClient from "../app/component/pages/Client-page/CreateClient";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "../app/component/pages/ErrorBoundary";
import PrivateRoute from "./PrivateRoute";
import AuthRoute from "./AuthRoute";
import { Navigate } from "react-router-dom";
import { ScrollToTop } from "../app/common/CommonFunctions/CommonFunctions";
import PasswordCode from "../app/component/pages/Password/PasswordCode";
import ResetPassword from "../app/component/pages/Password/ResetPassword";
import VerifyEmail from "../app/component/pages/Create-Account/VerifyEmail";
const router = createBrowserRouter([
  {
    path: "/auth",
    element: <AuthRoute />,
    errorElement: <Login />,
    children: [
      {
        index: true,
        element: <Navigate to="login" replace />,
      },
      {
        path: "login",
        element: (
          <>
            <ScrollToTop />
            <Login />
          </>
        ),
      },
      {
        path: "register",
        element: (
          <>
            <ScrollToTop />
            <CreateAccount />
          </>
        ),
      },
      {
        path: "register-code",
        element: (
          <>
            <ScrollToTop />
            <VerifyEmail />
          </>
        ),
      },
      {
        path: "forgot",
        element: (
          <>
            <ScrollToTop />
            <ForgotPassword />
          </>
        ),
      },

      {
        path: "code",
        element: (
          <>
            <ScrollToTop />
            <PasswordCode />
          </>
        ),
      },
      {
        path: "reset",
        element: (
          <>
            <ScrollToTop />
            <ResetPassword />
          </>
        ),
      },
    ],
  },
  {
    path: "/",
    element: <PrivateRoute />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <Header />,
        children: [
          {
            path: "dashboard",
            element: <DashboardPage />,
          },
          {
            path: "manage-account",
            element: <ManageAccount />,
          },
          {
            path: "candidates",
            element: <CandidateTableList />,
          },
          {
            path: "jobs",
            element: <JobsTableList />,
          },
          {
            path: "clients",
            element: <ClientTableList />,
          },
          {
            path: "reports",
            element: <ReportsPage />,
          },
          {
            path: "create-candidate",
            element: <CandidatePage />,
          },
          {
            path: "import-candidate",
            element: <ImportCandidate />,
          },
          {
            path: "resume-upload",
            element: <ResumeUpload />,
          },
          {
            path: "candidate-info",
            element: <EmployeeInfo />,
          },
          {
            path: "create-job",
            element: <CreateJob />,
          },
          {
            path: "create-client",
            element: <CreateClient />,
          },
        ],
      },
    ],
  },
]);

const AppRouter = () => {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
};

export default AppRouter;

import React from "react";
import styled from "@emotion/styled";

export const StyledButton = styled("button")({
  backgroundColor: "#0D52A7",
  color: "white",
  padding: "12px 32px",
  border: "none",
  borderRadius: "8px",
  cursor: "pointer",
  fontWeight: "500",
  fontSize: "14px",
  // "&:hover": {
  //   backgroundColor: "#5a5c5a",
  // },
});

export const CancelButton = styled(StyledButton)({
  backgroundColor: "#F5F4F3",
  border: "1px solid #ACACAC",
  color: "#7B7A7A",
  fontWeight: "500",
  fontSize: "14px",
  padding: "12px 32px",
  borderRadius: "8px",
  cursor: "pointer",
  marginRight: "25px",
  // "&:hover": {
  //   backgroundColor: "#b9b7b7",
  // },
});

function Button(props) {
  const {
    event = () => "",
    type = "button",
    label = "",
    className = "",
    loading = false,
    customLoading = false,
    disabled = false,
    isCancel = false,
    style = {},
    icon = null,
  } = props || {};
  const ButtonComponent = isCancel ? CancelButton : StyledButton;
  return (
    <ButtonComponent
      onClick={event}
      type={type}
      className={className}
      style={{
        ...style,
        cursor: loading || customLoading ? "no-drop" : "pointer",
      }}
      disabled={disabled}
    >
      {label}
      {icon}
    </ButtonComponent>
  );
}

export default Button;

import styled from "@emotion/styled";
import Button from "../Button/Button"
export const StyledPopupMainContainer = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "rgba(0, 0, 0, 0.5)",
  zIndex: "9999999"
})

export const StyledPopupOuter = styled("div")({
  background: "white",
  padding: "20px",
  borderRadius: "8px",
  width: "400px",
  maxWidth: "90%",
  position: "relative",
})

export const StyledPopupHeading = styled("div")({
  position: "absolute",
  top: "10px",
  right: "10px",
})

export const StyledCloseIcon = styled("img")({
  background: "none",
  border: "none",
  fontSize: "24px",
  cursor: "pointer"
})

export const StyledPopupContent = styled("div")({
  textAlign: "center"
})

export const StyledPopupBody = styled("div")({
  padding: "20px 10px"
})

export const StyledPopupActions = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
  gap: "30px",
})

export const StyledPopupTitle = styled("h2")({
  color: "black",
  marginTop: "-6px"
})

export const StyledPopupSubtitle = styled("p")({
  fontWeight: "600",
  fontSize: "15px",
  padding: "6px 1px",
  borderRadius: "5px",
  color: "#3772c9",
  marginTop: "-6px"
})
export const StyledButton = styled(Button)({
  padding: "10px 30px",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
  margin: "0 5px",
  "&.cancel-button": {
    background: "#ccc",
    color: "black"
  },
  "&.confirm-button": {
    background: "red",
    color: "white"
  },
})



import React, { useState, useRef, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import styled from "@emotion/styled";
function PageSelector(props) {
  const {
    dropdownHeading = "",
    options = [],
    onOptionSelect,
    dropdownStyle = {},
    dropdownOptionStyle = {},
  } = props || {};
  const [showDropdown, setShowDropdown] = useState(false);
  const [showdropdownicon, setShowdropdownicon] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const StyledDropDownContent = styled("div")({
    background: "#1b52a7",
    color: "white",
    width: "167px",
    padding: "8px",
    display: "flex",
    gap: "10px",
    cursor: "pointer",
    borderRadius: "5px",
    alignItems: "center",
    marginRight: "15px",
    justifyContent: "space-between",
  });
  const StyledOptions = styled("div")({
    width: "85%",
    background: "white",
    padding: "8px",
    color: "black",
    borderBottom: "1px solid silver",
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "5px",
    marginRight: "15px",
    display: "flex",
    alignItems: "center",
    height: "20px",
    justifyContent: "space-between",
    "&:hover": {
      background: "#cddcec",
    },
    // "&:active": {
    //   background: "#4ea1efad",
    //   color: "white",
    // },
  });
  const StyledDropDownContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    position: "relative",
    marginTop: "10px",
    marginRight: "10px",
    zIndex: "99"
  });
  const StyledOptionsContainer = styled("div")({
    width: "100%",
    position: "absolute",
    right: "0",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    top: "41px",
    zIndex: "999999",
  });

  const handleOptionClick = (option) => {
    setShowDropdown(false);
    onOptionSelect(option);
  };

  return (
    <StyledDropDownContainer
      ref={dropdownRef}
      className="drop-down-content-outer-page"
    >
      <StyledDropDownContent
        onClick={() => setShowDropdown(!showDropdown)}
        className="drop-down-content"
        style={dropdownStyle}
      >
        {dropdownHeading}
        {showDropdown ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </StyledDropDownContent>
      {showDropdown && (
        <StyledOptionsContainer className="option-container">
          {options.map((option, index) => (
            <StyledOptions
              key={index}
              onClick={() => handleOptionClick(option)}
              className="options"
              style={dropdownOptionStyle}
              onMouseEnter={() => setShowdropdownicon(option)}
              onMouseLeave={() => setShowdropdownicon(null)}
            >
              {option.name}
              {showdropdownicon === option && option.icon && (
                <span className="option-icon">{option.icon}</span>
              )}
            </StyledOptions>
          ))}
        </StyledOptionsContainer>
      )}
    </StyledDropDownContainer>
  );
}

export default PageSelector;

import React from "react";
import * as ModalStyledDOM from "./ModalPopup.jsx";
import closeIcon from "../../../assets/image/Close-icon.svg";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import deleteAnimation from "../../../assets/json/Delete-Animation.json";
const ModalPopup = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmLabel,
  cancelLabel,
  isLoading,
}) => {
  if (!isOpen) return null;

  return (
    <ModalStyledDOM.StyledPopupMainContainer>
      <ModalStyledDOM.StyledPopupOuter>
        <ModalStyledDOM.StyledPopupHeading>
          <ModalStyledDOM.StyledCloseIcon onClick={onClose} src={closeIcon} />
        </ModalStyledDOM.StyledPopupHeading>
        <ModalStyledDOM.StyledPopupContent>
          <ModalStyledDOM.StyledPopupBody>
            <ModalStyledDOM.StyledPopupTitle>{title}</ModalStyledDOM.StyledPopupTitle>
            <ModalStyledDOM.StyledPopupSubtitle>{message}</ModalStyledDOM.StyledPopupSubtitle>
            <Player
              autoplay
              loop
              src={deleteAnimation}
              style={{ height: "200px", width: "350px" }}
              className="logout-animation"
            >
              <Controls
                visible={false}
                buttons={["play", "repeat", "frame", "debug"]}
              />
            </Player>
            <ModalStyledDOM.StyledPopupActions>
              <ModalStyledDOM.StyledButton
                className="cancel-button"
                event={onClose}
                label={cancelLabel}
              ></ModalStyledDOM.StyledButton>
              <ModalStyledDOM.StyledButton
                className={`confirm-button ${isLoading ? "loading" : ""}`}
                event={onConfirm}
                disabled={isLoading}
                label={isLoading ? "Deleting..." : confirmLabel}
              />
            </ModalStyledDOM.StyledPopupActions>
          </ModalStyledDOM.StyledPopupBody>
        </ModalStyledDOM.StyledPopupContent>
      </ModalStyledDOM.StyledPopupOuter>
    </ModalStyledDOM.StyledPopupMainContainer>
  );
};

export default ModalPopup;
